import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Header from "../../components/regions/header"
import Container from "../../components/block/container"
import FieldText from "../../components/field/fieldText"
import ImageTextPositioned from "../../components/item/imageTextPositioned"
import BlockTry from "../../components/full/blockTry"
import MarketingAgenciesStrip from "../../assets/images/twisted-strip--marketing-agencies.png"
import CustomerSupportStrip from "../../assets/images/twisted-strip--customer-support-teams.png"
import DecoratedImage from "../../components/item/decoratedImage"
import "../../styles/components/block--page-top-content--use-case.scss"
import "../../styles/components/block--use-cases.scss"
import "../../styles/components/block--menu-secondary-trigger.scss"

const UseCasesPage = () => {
  return (
    <>
      <Header />
      <Layout>
        <Seo
          title="Use Cases"
          description="Who it's for?"
          pageClass="page--use-cases"
        />

        <Container classes="block--page-top-content block--page-top-content--use-case">
          <FieldText>
            <h3 className="text text--red text--uppercase text--centered">
              Use cases
            </h3>
            <h1 className="text text--centered">Who it's for</h1>
          </FieldText>
        </Container>

        <Container classes="block--use-cases">
          <ImageTextPositioned position="left">
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/creative-agencies.jpg"
              quality="90"
              placeholder="blurred"
              alt="Creative Agencies"
              title="Creative Agencies"
            />
            <FieldText>
              <h3>Creative Agencies</h3>
              <h4>Lorem Dolor ipsum conseqtetur</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.{" "}
              </p>
              <Link
                to={"/use-cases/creative-agencies/"}
                className="link link--more"
              >
                Read More
              </Link>
            </FieldText>
          </ImageTextPositioned>

          <ImageTextPositioned position="right">
            <DecoratedImage
              classes="field--image"
              stripSrc={MarketingAgenciesStrip}
              stripClasses={"marketing-agencies-strip"}
            >
              <StaticImage
                as="figure"
                className="field"
                src="../../assets/images/marketing-agencies.jpg"
                quality="90"
                placeholder="blurred"
                alt="Marketing Agencies"
                title="Marketing Agencies"
              />
            </DecoratedImage>
            <FieldText>
              <h3>Marketing Agencies</h3>
              <h4>Lorem Dolor ipsum conseqtetur</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.{" "}
              </p>
              <Link
                to={"/use-cases/marketing-agencies/"}
                className="link link--more"
              >
                Read More
              </Link>
            </FieldText>
          </ImageTextPositioned>

          <ImageTextPositioned position="left">
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/dev-teams.jpg"
              quality="90"
              placeholder="blurred"
              alt="Dev Teams"
              title="Dev Teams"
            />
            <FieldText>
              <h3>Dev Teams</h3>
              <h4>Lorem Dolor ipsum conseqtetur</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.{" "}
              </p>
              <Link to={"/use-cases/dev-teams/"} className="link link--more">
                Read More
              </Link>
            </FieldText>
          </ImageTextPositioned>

          <ImageTextPositioned position="right">
            <StaticImage
              as="figure"
              className="field field--image"
              src="../../assets/images/in-house-creative-teams.jpg"
              quality="90"
              placeholder="blurred"
              alt="In-House Creative Teams"
              title="In-House Creative Teams"
            />
            <FieldText>
              <h3>In-House Creative Teams</h3>
              <h4>Lorem Dolor ipsum conseqtetur</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.{" "}
              </p>
              <Link
                to={"/use-cases/in-house-creative-teams/"}
                className="link link--more"
              >
                Read More
              </Link>
            </FieldText>
          </ImageTextPositioned>

          <ImageTextPositioned position="left">
            <DecoratedImage
              classes="field--image"
              stripSrc={CustomerSupportStrip}
              stripClasses={"customer-support-teams-strip"}
            >
              <StaticImage
                as="figure"
                className="field field--image"
                src="../../assets/images/customer-support-team.jpg"
                quality="90"
                placeholder="blurred"
                alt="Customer Support Teams"
                title="Customer Support Teams"
              />
            </DecoratedImage>
            <FieldText>
              <h3>Customer Support Teams</h3>
              <h4>Lorem Dolor ipsum conseqtetur</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. A
                consectetur culpa et nulla, odio officia officiis quam quas quia
                quod sit.
              </p>
              <Link
                to={"/use-cases/customer-support-teams/"}
                className="link link--more"
              >
                Read More
              </Link>
            </FieldText>
          </ImageTextPositioned>
        </Container>

        <BlockTry />
      </Layout>
    </>
  )
}

export default UseCasesPage
